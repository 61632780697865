import React, { useEffect } from "react";
import "./styles.css";
import { Navbar } from "../components";

const Wrapper = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar home={true} />
      <div className="section__padding">{children}</div>
    </>
  );
};

export default Wrapper;
