import React from 'react';
import "./forClients.css"
import { SectionTitle } from "../../components";

// Import the images
import contentCreatorsImg from "../../assets/GraphicDesign.jpg";
import individualsImg from '../../assets/Individuals.jpg';
import marketersImg from '../../assets/Marketers.jpg';

// Define the ForClients component
const ForClients = () => {
  return (
    <div className="for-clients section__padding" id="services">
      {/* <SectionTitle title="Designs for" /> */}
      <div className="services-section">
        <div className="service">
          <img src={contentCreatorsImg} alt="For Content Creators" />
          <h2>For Content Creators</h2>
          <p>Custom graphics and Canva templates to make your content stand out.</p>
        </div>
        <div className="service">
          <img src={marketersImg} alt="For Marketers" />
          <h2>For Marketers & Business Owners</h2>
          <p>Engaging visuals for your brand designed to capture your target audience.</p>
        </div>
        <div className="service">
          <img src={individualsImg} alt="For Individuals" />
          <h2>For Individuals</h2>
          <p>Personalized art pieces created with AI and hand-drawn.</p>
        </div>
      </div>
    </div>
  );
}

export default ForClients;
