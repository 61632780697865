import React from "react";
import "./feature.css";

const Feature = ({ title, children, src, href }) => {
  return (
    <a
      className="feature_container"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <div className="feature-title">
        <div className="bar"></div>
        <h1 className="gradient__text">{title}</h1>
      </div>
      <p>{children}</p>
      <img src={src} alt="mamaminia.art-feature" />
    </a>
  );
};

export default Feature;
