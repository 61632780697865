import React from "react";
import Design from "../../assets/graphic2.png";
import Timer from "../../assets/timer.png";
import Const from "../../assets/const.png";
import Colors from "../../assets/colors.png";
import "./header.css";

// Have a modern website with original design
// Get unique digital art

const Main = () => {
  return (
    <div className="main section__padding" id="home">
      {/* <img className="fixedImage" src={Design} /> */}
      <div className="title">
        <p>Let's create your</p>
        <p className="serif gradient__text">
          <b>visual identity.</b>
        </p>
        {/* <p className="withBg">Your brand should build a relationship with your audience.</p> */}
        {/* <p>Let's create</p>
        <p>
          <s className="gradient__text">
            <b>design </b>
          </s>
          for
        </p>
        <p>
          your{" "}
          <s className="gradient__text">
            <b>brand</b>
          </s>
        </p> */}
      </div>
      <div>
        <img src={Colors} />
      </div>
      {/* <div className="graphic"><img src={Design} /></div> */}
     {/*  <div className="problems">
        <div>
          <img src={Timer} alt="" />
          <div>
            <h1 className="text__highlight_1">First 5 seconds matters</h1>
            <p>
              In tis time you WIN or LOOSE the{" "}
              <span className="text__highlight_1">
                <b>ATTENTION</b>
              </span>
            </p>
          </div>
        </div>
        <div>
          <img src={Const} alt="" />
          <div>
            <h1 className="text__highlight_1">Consistency is the key</h1>
            <p>
              Be consistent with all{" "}
              <span className="text__highlight_2">
                <b>DESIGNS</b>
              </span>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Main;
