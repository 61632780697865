import React from "react";
import "./header.css";
import Main from "./Main";

// Have a modern website with original design
// Get unique digital art

const Header = () => {
  return (
    <div className="header" id="home">
      <Main />
      {/* <div className="header-content">
        <h1 className="gradient__text">
          Art and Websites created with love&hearts;
        </h1>
        <h2>
          Have a modern website. Get a unique design. Connect for nice and
          friendly cooperation.
        </h2>
      </div>
      <div className="header-image">
        <img src={Image} alt="Ballet" />
      </div> */}
    </div>
  );
};

export default Header;
