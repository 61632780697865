import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Impressum from "./routes/Impressum";
import Portfolio from "./routes/Portfolio2";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="impressum" element={<Impressum />} />
    </Routes>
  </BrowserRouter>
);
