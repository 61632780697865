import React from "react";
import CTAButton from "./CTAButton";

const GumroadLink = () => {
  return (
    <CTAButton>
      <a href="https://mamaminia.gumroad.com/" target="_blank" rel="noreferrer">
        See on Gumroad
      </a>
    </CTAButton>
  );
};

export default GumroadLink;
