import React from "react";
// import { FaReact, FaHtml5, FaCss3, FaNodeJs } from "react-icons/fa";
// import { SiJavascript, SiGithub, SiKrita } from "react-icons/si";
import "./features.css";
import { CTA, Feature } from "../../components";
import DigitalArt from "../../assets/design.png";
// import Newsletter from "../../assets/newsletter.png";
import Youtube from "../../assets/youtube.png";
import Gumroad from "../../assets/gumroad.png";

// const skills = [
//   { title: "JavaScript", icon: <SiJavascript /> },
//   { title: "React", icon: <FaReact /> },
//   { title: "HTML", icon: <FaHtml5 /> },
//   { title: "CSS", icon: <FaCss3 /> },
//   { title: "NodeJs", icon: <FaNodeJs /> },
//   { title: "Github", icon: <SiGithub /> },
//   { title: "Krita", icon: <SiKrita /> },
// ];

const Features = () => {
  return (
    <div className="features section__padding" id="features">
      {/* <SectionTitle title="What can we create together?" /> */}
      <div className="features_container">
        <Feature title={"Art & Design"} src={DigitalArt} href="/portfolio">
          Portfolio - illustrations & graphics, ai-art, digital art, paintings & drawings.
        </Feature>
        <Feature
          title={"YouTube"}
          src={Youtube}
          href="https://www.youtube.com/channel/UCGc2uA6FV1LINPtEfZFm02g"
        >
          Video Portfolio - tutorials, drawing process, inspiring projects & more.
        </Feature>
        <Feature
          title={"Gumroad"}
          src={Gumroad}
          href="https://mamaminia.gumroad.com/"
        >
          Design Magic Box, drawing tutorials, workbooks & more.
        </Feature>
        {/* <Feature
          title={"Newsletter"}
          src={Newsletter}
          href="https://letsdrawsomething.beehiiv.com/"
        >
          Experience, knowledge & thoughts.
        </Feature> */}
      </div>
      {/* <div className="features-skills_container">
        {skills.map(({ title, icon }) => (
          <div key={title}>
            {icon}
            <p>
              <i>{title}</i>
            </p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Features;
