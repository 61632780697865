import React, { useState } from "react";
import "./cta.css";

const SubscribeForm = () => {
  const [email, setEmail] = useState("");

  // Handles form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form data setup
    const formData = new FormData();
    formData.append("seller_id", "9007524948831");
    formData.append("email", email);

    // Fetch options
    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://app.gumroad.com/follow_from_embed_form",
        requestOptions
      );
      const result = await response.text(); // or response.json() if the server responds with JSON
      console.log(result);
      alert("Subscription successful!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting form.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="hidden" name="seller_id" value="9007524948831" />
      <input
        type="email"
        placeholder="Your email address"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button type="submit">Subscribe</button>
    </form>
  );
};

export default SubscribeForm;
