import React from "react";
import GumroadLink from "../../components/cta/GumroadLink";
import SubscribeForm from "../../components/cta/SubscribeForm";
import { SectionTitle } from "../../components";
import DMB from "../../assets/DMB.jpg";
import DMBPromo from "../../assets/DMBpromo.jpg";

const examples = ["posts", "covers", "banners", "thumbnails", "blog graphics", "advertising materials", "brand identity elements", "presentation designs", "website graphics", "background images", "wall deco", "inspiring framed graphics", "invitations", "prints", "posters", "scrapbooking", "stickers", "greeting cards", "affirmation designs", "mood boards", "journaling"]

const DesignMagicBox = () => {
  return (
    <div className="section__padding designMagicBox" id="dmb">
      <SectionTitle title="Discover free graphics & Canva Templates" />
      <div className="dmb">
        <img className="promo" src={DMBPromo} />
        <div className="info">
          <img src={DMB} />
          <GumroadLink />
        </div>
      </div>
      <div className="description">
        <div>
          <p className="main">
            Get every Friday cool art stuff:
            <ul>
              <li>graphics & illustrations</li>
              <li>Canva templates</li>
              <li>coloring pages</li>
              <li>prompts</li>
              <li>clipart</li>
            </ul>
            in your mailbox.
          </p>
          <SubscribeForm />
        </div>
        <div className="details">
          <p>How can you use Design Magic Box?</p>
          <ul>
            <li>
              <b>personal creative projects</b>
            </li>
            <li>
              <b>personal development</b>
            </li>
            <li>
              <b>wall art</b>
            </li>
            <li>
              <b>content visuals</b>
            </li>
            <li>
              <b>marketing and branding</b>
            </li>
            <li>
              <b>digital art and illustration</b>
            </li>
            <li>
              <b>web design</b>
            </li>
            <li>
              <b>wallpapers</b>
            </li>
          </ul>
        </div>
      </div>
      <div className="how-to-use">
        {examples.map((ex) => (
          <div className="example" key={ex}>
            {ex}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DesignMagicBox;
