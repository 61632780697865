import React from "react";
import { CTA, SectionTitle } from "../../components";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact section__padding" id="contact">
      <SectionTitle title="Need more engagement with art and design?" />
      <h2 className="contact-subtitle">Contact me per email</h2>
      <CTA />
    </div>
  );
};

export default Contact;
