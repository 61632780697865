import React from "react";
import {
  FaChildDress,
  FaBabyCarriage,
  FaMagnifyingGlass,
  FaPalette,
} from "react-icons/fa6";
import { LuBaby } from "react-icons/lu";
import {
  FaRegStar,
  FaUserGraduate,
  FaTruckMoving,
  FaCode,
  FaPaintBrush,
} from "react-icons/fa"; // Example icon, change as needed
import "./whatIDo.css"; // Importing the CSS file

import { SectionTitle } from "../../components";

const MyStory = () => {
  const events = [
    { date: "1989", description: "Born in Wrocław, Poland.", icon: <LuBaby /> },
    {
      date: "2008",
      description: (
        <>
          <p>Graduated high school.</p>
          <p>Began university studies in Engineering.</p>
        </>
      ),
      icon: <FaChildDress />,
    },
    {
      date: "2013",
      description: (
        <>
          <p>Graduated from Wrocław University of Technology.</p>
          <p>Worked as an engineer.</p>
        </>
      ),
      icon: <FaUserGraduate />,
    },
    {
      date: "2015",
      description: "Moved to Germany for a new job opportunity.",
      icon: <FaTruckMoving />,
    },
    {
      date: "2018",
      description:
        "Changed profession from engineer to web developer - first steps in web design.",
      icon: <FaCode />,
    },
    {
      date: "2020",
      description: (
        <>
          <p>Became a mother for the first time.</p>
          <p>
            Defined my <b>core values</b>: family, freedom, health, development.
          </p>
        </>
      ),
      icon: <FaBabyCarriage />,
    },
    {
      date: "2021",
      description: (
        <>
          <p>
            Discovered a passion for digital art and painting with watercolor &
            gouache.
          </p>
          <p>Got married.</p>
        </>
      ),
      icon: <FaPalette />,
    },
    {
      date: "2022",
      description: (
        <>
          <p>Became an Artist & Graphic Designer.</p>
          <p>Started learning and sharing art & design fundamentals.</p>
        </>
      ),
      icon: <FaPaintBrush />,
    },
    {
      date: "2023",
      description: (
        <>
          <p>Became a mother for the second time.</p>
          <p>Came back to Poland.</p>
          <p>Continued developing artistic skills.</p>
        </>
      ),
      icon: <FaBabyCarriage />,
    },
    {
      date: "2024",
      description: (
        <>
          <p>
            Looking for new job opportunities as a <b>Graphic Designer</b>.
          </p>
          <p> Working hard on my content and Design Magic Box project.</p>
        </>
      ),
      icon: <FaMagnifyingGlass />,
    },
  ];

  return (
    <div className="about section__padding" id="about">
      <SectionTitle title="My story" />
      <div className="story-container">
        {events.map((event, index) => (
          <div key={index} className="story-event">
            <div className="story-icon">{event.icon}</div>
            <h3>{event.date}</h3>
            <p>{event.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyStory;
