import React from "react";
import "./styles.css";
import Wrapper from "./Wrapper";

const Impressum = () => (
  <Wrapper>
    <h1>Impressum</h1>
    <h3>Izabela Ograbek - art. design. web. by mamaminia</h3>
    <p>
      Izabela Ograbek
      <br />
      Eichbergweg 18<br />
      02699 Königswartha
      <br />
      Germany
    </p>
    <p>
      Mobile: +49 1578 2785218
      <br />
      Mail: contact@mamaminia.art
    </p>
    <p>VAT ID (USt-IdNr.): DE350572741</p>
  </Wrapper>
);

export default Impressum;
