import React from "react";
import { Link } from "react-router-dom";
import {
  BsInstagram,
  BsYoutube,
  BsLinkedin,
  BsPinterest,
  // BsTwitter,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import "./footer.css";
import image from "../../assets/me.png";
import Menu from "../../components/navbar/Menu";

const social = [
  {
    icon: <BsYoutube />,
    href: "https://www.youtube.com/channel/UCGc2uA6FV1LINPtEfZFm02g",
  },
  {
    icon: <BsInstagram />,
    href: "https://www.instagram.com/mamaminia_art/",
  },
  {
    icon: <BsPinterest />,
    href: "https://pin.it/3MbFnid",
  },
  {
    icon: <BsLinkedin />,
    href: "https://www.linkedin.com/in/izabela-ograbek/",
  },
  {
    icon: <FaTiktok />,
    href: "https://www.tiktok.com/@mamaminia.art",
  },
  /* {
    icon: <BsTwitter />,
    href: "https://twitter.com/mamaminiaArt",
  }, */
];

const Footer = ({ subpage = false }) => {
  return (
    <div className="footer section__padding" id="footer">
      <div className="footer-links">
        <div className="footer-links_wrapper">
          <h4>Links</h4>
          {subpage ? (
            <p>
              <Link to="/">Home</Link>
            </p>
          ) : (
            <Menu contact={false} />
          )}
        </div>
        <div className="footer-links_wrapper">
          <h4>My Content</h4>
          <p>
            <a
              href="https://www.youtube.com/channel/UCGc2uA6FV1LINPtEfZFm02g"
              target="_blank"
              rel="noreferrer"
            >
              YouTube
            </a>
          </p>
          <p>
            <a
              href="https://mamaminia.gumroad.com/"
              target="_blank"
              rel="noreferrer"
            >
              Gumroad
            </a>
          </p>
          <p>
            <a
              href="https://www.linkedin.com/in/izabela-ograbek/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </p>
          {/* <p>
            <a
              href="https://www.etsy.com/shop/mamaminiaArt"
              target="_blank"
              rel="noreferrer"
            >
              Etsy Shop
            </a>
          </p> */}
          {/* <p>
            <a
              href="https://www.redbubble.com/people/mamaminiaArt/shop?asc=u"
              target="_blank"
              rel="noreferrer"
            >
              Redbubble Shop
            </a>
          </p> */}
        </div>
        <div className="footer-links_wrapper">
          <h4>Info</h4>
          <p>
            <a href="mailto:contact@mamaminia.art">contact@mamaminia.art</a>
          </p>
          {/* <p>
            <Link to="/impressum">Impressum</Link>
          </p>
          <p>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p> */}
        </div>
        <div className="footer-links_logo">
          <img src={image} alt="Footer logo" />
        </div>
      </div>
      <div className="footer-social">
        {social.map(({ icon, href }) => (
          <a key={href} href={href} target="_blank" rel="noreferrer">
            {icon}
          </a>
        ))}
      </div>
      <div className="footer-copyright">
        <p>mamaminia.art &copy; 2024 Izabela Ograbek All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
