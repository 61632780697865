import React, { useEffect, useRef, useState } from "react";
import Masonry from "react-masonry-css";
import "./portfolio.css";
import { Footer } from "../containers";

// Manually specified number of images for each section
const imageCounts = {
  "ai-art": 34,
  graphics: 10,
  carousels: 5,
  "digital-art": 28,
  "watercolor-gouache": 16,
};

const Portfolio = () => {
  const sections = [
    { id: "graphics", title: "Graphics", imagesLoaded: false },
    { id: "carousels", title: "Carousels", imagesLoaded: false },
    { id: "ai-art", title: "AI Art", imagesLoaded: false },
    { id: "digital-art", title: "Digital Art", imagesLoaded: false },
    {
      id: "watercolor-gouache",
      title: "Watercolor & Gouache",
      imagesLoaded: false,
    },
  ];
  const [loadedSections, setLoadedSections] = useState(new Set());

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoadedSections((prev) => new Set(prev.add(entry.target.id)));
          }
        });
      },
      { threshold: 0.5 }
    )
  );

  useEffect(() => {
    sections.forEach((section) => {
      const sectionRef = document.getElementById(section.id);
      if (sectionRef) {
        observer.current.observe(sectionRef);
      }
    });

    return () => {
      sections.forEach((section) => {
        const sectionRef = document.getElementById(section.id);
        if (sectionRef) {
          observer.current.unobserve(sectionRef);
        }
      });
    };
  }, []);

  const [selectedImg, setSelectedImg] = useState(null);

  const handleImageClick = (img) => {
    setSelectedImg(img);
  };

  const closeOverlay = () => {
    setSelectedImg(null);
  };

  const loadImages = (sectionId) => {
    const count = imageCounts[sectionId];
    const basePath = `${process.env.PUBLIC_URL}/portfolio-images/${sectionId}`;
    return Array.from({ length: count }, (_, i) => `${basePath}/${i}.webp`);
  };

  /* const loadImages = (sectionId) => {
    // Here you could fetch or generate image paths dynamically
    return Array.from(
      { length: 10 },
      (_, i) => `https://picsum.photos/200/300?random=${sectionId}${i}`
    );
  }; */

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <div>
      <nav>
        <a href="/">Home</a>
        {/* Navigation links would scroll to sections */}
        {sections.map((section) => (
          <a href={`#${section.id}`} key={section.id}>
            {section.title}
          </a>
        ))}
      </nav>

      {sections.map((section) => (
        <section key={section.id} id={section.id}>
          <h2>{section.title}</h2>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {loadedSections.has(section.id) &&
              loadImages(section.id).map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`${section.title} ${index + 1}`}
                  onClick={() => handleImageClick(src)}
                />
              ))}
          </Masonry>
        </section>
      ))}
      {selectedImg && (
        <div className="overlay" onClick={closeOverlay}>
          <img
            src={selectedImg}
            alt="Enlarged pic"
            onClick={(e) => e.stopPropagation()}
          />
          <button className="close-btn" onClick={closeOverlay}>
            ×
          </button>
        </div>
      )}
      <Footer subpage={true} />
    </div>
  );
};

export default Portfolio;
