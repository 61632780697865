import React from "react";
import "./cta.css";

const CTAButton = ({ children }) => {
  return (
    <div className="cta">
      <span>{children}</span>
    </div>
  );
};

export default CTAButton;
