import React from "react";

const Menu = ({ contact = true }) => {
  return (
    <>
      <p>
        <a href="/">Home</a>
      </p>
      <p>
        <a href="portfolio">Portfolio</a>
      </p>
      <p>
        <a href="/#services">Services</a>
      </p>
      <p>
        <a href="/#dmb">DesignMagicBox</a>
      </p>
      <p>
        <a href="/#features">Features</a>
      </p>
      <p>
        <a href="/#about">My story</a>
      </p>
      {contact ? (
        <p>
          <a href="#contact">
            Contact
          </a>
        </p>
      ) : null}
    </>
  );
};

export default Menu;
