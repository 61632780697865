import React from "react";
import "./App.css";
import {
  Header,
  Footer,
  Features,
  Contact,
  MyStory,
  ForClients,
  DesignMagicBox
} from "./containers";
import { Navbar } from "./components";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <ForClients />
      <DesignMagicBox />
      <Features />
      <MyStory />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
